<template>
  <div>
    <hr />
    <expansion-panel ref="personalFormPanel" label="View Personal Details">
      <form class="personal-details-form">
        <div class="module">
          <a v-if="!isRoleCoordinator && !editingPersonalDetails && getRole !='ONBOARDING_ACCOUNT_MANAGER' && getRole != 'TS_MANAGER'" class="button secondary" @click="editPersonalDetails">Edit Personal Details</a>
          <save-cancel v-if="editingPersonalDetails" :saveEnabled="disabledFlag" emit-cancel @onSave="savePersonalDetails" @onCancel="cancelPersonalDetails" />
        </div>
        <p v-if="errorFlag" class="error-message">Please correct errors before proceeding.</p>
        <div class="module">
          <!-- <form-input v-model="piiDetails.dob" label="Date of Birth" required :disabled="!editingPersonalDetails || isRoleOS" /> -->
          <date-picker-insurance :editFlag="editingPersonalDetails"  @input="validateForm"  :value="piiDetails.dob" :width="520" label="Date of Birth"  v-model="piiDetails.dob" :disabled="!editingPersonalDetails || isRoleOS" />
          <p class="error-message" v-if="ageValidationMessage">Age must be above 18 years</p>

          <!-- <form-input v-model="piiDetails.ein" label="EIN" :disabled="!editingPersonalDetails || isRoleOS" /> -->
          <form-input v-model="piiDetails.ssn" @input="validateForm" label="Social Insurance Number" required :disabled="!editingPersonalDetails || isRoleOS" />
          <h2>Drivers License</h2>
          
          <form-input v-model="piiDetails.licenseNumber" label="Drivers License Number" required :disabled="!editingPersonalDetails" @input="validateForm" />
          <p v-if="dlError" class="error-message">Invalid DL format</p>
          <p v-if="dlexist" class="error-message">Driver License already exists</p>
          <!-- <form-input v-model="piiDetails.licenseExpiry" label="Drivers License Expiration" required :disabled="!editingPersonalDetails" /> -->
          <!-- <form-input v-model="piiDetails.licenseState" label="Issuing province" required :disabled="!editingPersonalDetails" /> -->
          <!-- <date-picker   label="Drivers License Expiration"  v-model="piiDetails.licenseExpiry" :disabled="!editingPersonalDetails" /> -->
          <date-picker-insurance :editFlag="editingPersonalDetails" :disableDates="true" :isFutureDate="disabledDatesNew" :value="piiDetails.licenseExpiry" :width="520" label="Drivers License Expiration"  v-model="piiDetails.licenseExpiry" :disabled="!editingPersonalDetails"/>
          <form-select
                     header.type = 'select'
                      key="province"
                      className="state-sel"
                      :value="candidateDetails[provinces]"
                      label="Drivers License State"
                      required
                      v-model="piiDetails.licenseState"
                      default-choice="Select"
                      :options="provinces"
                      option-label="name"
                      option-value="name"
                      rules="required"
                      :disabled="!editingPersonalDetails"
                      @input="onSelectingProvince($event)"
                    />
          <h2>Address</h2>
          <form-input v-model="piiDetails.streetAddress" :disabled="!editingPersonalDetails" name="address" label="Address1" @input="getAddressSuggestions($event)" required  />
          <div class="box" v-if="addressSuggestions.length > 0">
            <div v-for="(item, index) in addressSuggestions" :key="index">
              <p class="suggestion-item" @click="setAddress(item.item)">{{ item.name }}</p>
            </div>
          </div>
          <form-input v-model="piiDetails.address2" name="address2" label="address2"  :disabled="!editingPersonalDetails" />
          <form-input v-model="piiDetails.city" name="city" label="City" required  :disabled=" true" />
          <div class="field">
            <!-- <label 
              >State* -->
              <!-- <form-input  name="state" /> -->
          <form-input v-model="piiDetails.state" name="state" label="Province" required  :disabled="true" />
            <!-- </label> -->
          </div>
          <form-input v-model="piiDetails.zip" @input="validateForm" label="Postal code" required :disabled="!editingPersonalDetails || editPostal" />
        </div>
        <div class="module">
          <save-cancel v-if="editingPersonalDetails" :saveEnabled="disabledFlag" emit-cancel @onSave="savePersonalDetails" @onCancel="cancelPersonalDetails" />
        </div>
      </form>
    </expansion-panel>
  </div>
</template>
<script>
import { ExpansionPanel, FormInput, SaveCancel,FormSelect } from "@/components";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";
// import Datepicker from "vuejs-datepicker";
import DatePickerInsurance from "@/components/forms/fields/DatePickerInsurance";
import { mapGetters } from "vuex";

export default {
  components: { FormInput, ExpansionPanel, SaveCancel,FormSelect,DatePickerInsurance },
  props: {
    candidate: {
      type: Object,
      required: true,
      default: () => {},
    },
    personalDetails: {
      type: Object,
      required: true,
      default: () =>{}
    }
  },
  data: () => ({
    ageValidationMessage: false,
    provinces:[
        {name:"Alberta"},
        {name:"British Columbia"},
        {name:"Manitoba"},
        {name:"New Brunswick"},
        {name:"Newfoundland and Labrador"},
        {name:"Northwest Territories"},
        {name:"Nova Scotia"},
        {name:"Nunavut"},
        {name:"Ontario"},
        {name:"Prince Edward Island"},
        {name:"Quebec"},
        {name:"Saskatchewan"},
        {name:"Yukon"}
      ],
      dlError:false,
      dlexist:false,
      editPostal:true,
      disabledDates: {
          to: new Date(Date.now() + 45 * 24 * 60 * 60 * 1000),
        },

        disabledDatesNew: new Date(Date.now() + 45 * 24 * 60 * 60 * 1000),
        disabledFlag:true,
    editingPersonalDetails: false,
    clientName:"",
    disableAddress: false,
    addressSuggestions: [],
    errorFlag: false,
    piiDetails: {
      ssn: "",
      dob: "",
      ein: "",
      licenseNumber: "",
      licenseExpiry: "",
      licenseState: "",
      streetAddress: "",
      address2: "",
      state: "",
      city: "",
      zip: "",
    
     
    }
  }),
  watch: {
    personalDetails(value) {
      Object.assign(this.piiDetails, value);
    },
    searchInput(newText, oldText) {
      if (oldText !== "") {
        if (newText !== oldText) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          // const value = newText;
          // this.getAddressSuggestions(value);
        }, 1000);
      }
    }
  },
  mounted() {
    this.clientName=process.env.VUE_APP_CLIENT_NAME;
    this.piiDetails.dob = this.$props.personalDetails.dob;
    this.piiDetails.ssn =this.$props.personalDetails.fullSsn
    this.piiDetails.licenseNumber =this.$props.personalDetails.licenseNumber
    this.piiDetails.licenseExpiry =this.$props.personalDetails.licenseExpiry
    this.piiDetails.licenseState =this.$props.personalDetails.licenseState
    this.piiDetails.streetAddress =this.$props.personalDetails.streetAddress
    this.piiDetails.state =this.$props.personalDetails.state
    this.piiDetails.city =this.$props.personalDetails.city
    this.piiDetails.address2 = this.$props.personalDetails.address2
    // console.log(this.$props.personalDetails,"addadddad   2")
    this.piiDetails.zip =this.$props.personalDetails.zip
  },
  computed: {
    ...mapGetters(["getRole"]),

    candidateID() {
      return this.$props.candidate ? this.$props.candidate.id : null;
    },
    isRoleCoordinator() {
      return localStorage.getItem("role") == "COORDINATOR";
    },
    isRoleOS() {
      return localStorage.getItem("role") == "ONBOARDING_SPECIALIST";
    },
    searchInput() {
      return this.piiDetails.streetAddress;
    },
  },
  methods: {
    dateCheck(val) {
      // this.validator = true
      var today = new Date();
      var birthDate = new Date(val);
      var age = today.getFullYear() - birthDate.getFullYear();
      let monthDifference = today.getMonth() - birthDate.getMonth();
      let dayDifference = today.getDate() - birthDate.getDate();

      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
      }

      if (age < 18) {
        this.ageValidationMessage = true;
        // this.disabledFlag=false;
      } else {
        this.ageValidationMessage = false;
        // this.disabledFlag=true;
      }
      return this.ageValidationMessage;
    },

    countZip(value){
      // console.log("value count",value)
      this.piiDetails.zip = value.replace(/\s/g,'');
      if(value.replace(/\s/g,'').length == 6 ){
        // this.disabledFlag = true;
        return false
      }else{
        // this.disabledFlag = false;
        return true
      }
    },

    customFormatter(date) {
         return date.toISOString().split('T')[0]
      },
    onSelectingProvince( value){
        this.candidateDetails["provinces"] = value == this.piiDetails.licenseState ==this.$props.personalDetails.licenseState;
      },
      checkSSN(value){
        let ssnReg = /^\d{3}\d{3}\d{3}$/;
        // console.log(value,"value of ssn",value.replace(/\s/g,''))
        if(value.replace(/\s/g,'').length == 9 && ssnReg.test(value)){
          // this.disabledFlag = true;
          return false;
        }else{
          // this.disabledFlag = false;
          return true;
        }
      },
      candidateDetails() {
        return {
          licenseState: this.$refs.license._props.value,
        };
      },
      async validateForm(){
        if(this.dateCheck(this.piiDetails.dob)){
        this.disabledFlag = false;
        return;
        }
        if(this.checkSSN(this.piiDetails.ssn)){
          // console.log("invalid ssn")
           this.disabledFlag =false;
           return;
        }
        if(this.countZip(this.piiDetails.zip)){
          this.disabledFlag=false;
          return;
        }
        if(await this.dlValidation(this.piiDetails.licenseNumber)){
          this.disabledFlag =false;
           return;
        }

        return this.disabledFlag = true;
      },
      async dlValidation(value){
        this.dlError=false;
        this.dlexist=false;
        const regexDL = /^[A-Za-z0-9*]+$/;
        const validDl = regexDL.test(value)
        if(!validDl){
          this.dlError=true;
          return this.dlError;
        }
        const obj = {
          licenseNumber: value,
          partnerID : this.$props.candidate.partnerID
        }
        let result = await restApi.post(`/partner/updateDL`, encodeWithParam(obj));
        if (!result.data.success) this.dlexist = true;
        return this.dlexist
      },
    getAddressSuggestions(searchText) {
      
      const searchParams = encodeWithParam({inputval: searchText, type: 'address'});
      restApi.post("/employee/placeAutocomplete", searchParams)
        .then(({ data }) => {
          if (data.result.predictions.length > 0) {
            this.addressSuggestions = data.result.predictions.map((item) => {
              return {
                name: `${item.description}`,
                item: `${item.place_id}`,
              };
            });
            this.disableAddress = true;
          } else {
            this.addressSuggestions = [
              {
                name: "Unknown address, click here to enter manually",
                item: {},
              },
            ];
            this.disableAddress = false
          }
        })
    },
    setAddress(data) {
      const searchParams = encodeWithParam({place_id: data});
      restApi.post("/employee/getPlaceDetails", searchParams)
      .then((data) => {
          let addressComponents = data.data.result.result.address_components;
          if (addressComponents.length > 0) {

            this.piiDetails.streetAddress =( (addressComponents.find(component => component.types.includes('street_number'))?.long_name || '') + ' ' + (addressComponents.find(component => component.types.includes('route'))?.long_name || '')).normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.piiDetails.city = (addressComponents.find(component => component.types.includes('locality'))?.long_name || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.piiDetails.state = (addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.piiDetails.zip = addressComponents.find(component => component.types.includes('postal_code'))?.short_name || '';
            this.addressSuggestions = [];
            if(this.piiDetails.zip.length < 6){
              this.editPostal =false
              this.disabledFlag = false;
            }else{
              this.editPostal=true
              this.disabledFlag = true;
            }
          }
        })

       
      
    },
    editPersonalDetails() {
      this.editingPersonalDetails = true;
      this.toggleMaskDetails(false);
      this.disableAddress = false;

    },
    toggleMaskDetails(mask) {
      // console.log(this.$props.personalDetails.decoded, "this.$props.personalDetails.decoded")
      const decoded = !mask ? this.$props.personalDetails.decoded : this.$props.personalDetails;
      if (!this.isRoleOS) {
        this.piiDetails.ssn = mask ? decoded.ssn : decoded.dssn;
        this.piiDetails.dob = mask ? decoded.dob : decoded.ddateOfBirth;
        this.piiDetails.ein = mask ? decoded.ein : decoded.dein;
      }
      this.piiDetails.licenseNumber = mask ? decoded.licenseNumber : decoded.dnumber;
      this.piiDetails.licenseExpiry = mask ? "" : decoded.dexpiration;
      this.piiDetails.licenseState = mask ? decoded.licenseState : decoded.dlicenseState
      this.piiDetails.streetAddress = mask ? decoded.streetAddress : decoded.daddress1;
      this.piiDetails.city = mask ? decoded.city : decoded.dcity;
      this.piiDetails.address2 = mask ? decoded.address2 : decoded.daddress2;
      this.piiDetails.state = mask ? decoded.state : decoded.dstate;
      this.piiDetails.zip = mask ? decoded.zip : decoded.dpostalCode;
    },
    savePersonalDetails() {
      if(this.piiDetails.ssn == ""  || this.piiDetails.dob == "" || this.piiDetails.licenseState == "" || this.piiDetails.licenseNumber == "" || this.piiDetails.licenseExpiry == "" || this.piiDetails.licenseExpiry == "" || this.piiDetails.streetAddress == "" || this.piiDetails.city == "" || this.piiDetails.state == "" || this.piiDetails.zip == "" ){
        this.errorFlag = true;
        
      }else{
          this.errorFlag = false;
           
          this.piiDetails.licenseState = this.piiDetails.licenseState.trim()
          this.piiDetails.licenseNumber = this.piiDetails.licenseNumber.trim()
          this.piiDetails.licenseExpiry = this.piiDetails.licenseExpiry.trim()
          this.piiDetails.streetAddress = this.piiDetails.streetAddress.trim()
          this.piiDetails.address2 = this.piiDetails.address2.trim()
          this.piiDetails.city = this.piiDetails.city.trim()
          this.piiDetails.state = this.piiDetails.state.trim()
          this.piiDetails.zip = this.piiDetails.zip.trim()
          const obj = Object.assign({}, this.piiDetails);
          this.$emit("onSavePersonalDetail", obj);
          this.cancelPersonalDetails();
        }
      
      
    },
    cancelPersonalDetails() {
      this.editingPersonalDetails = false;
      this.toggleMaskDetails(true);
      this.disableAddress = false;
      this.errorFlag = false;
      this.addressSuggestions = []
    },
    isMasked(value){
      return value.includes("*")
    },

  },
};
</script>
<style scoped>
.box {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  font-family: "Open Sans";
  position: absolute;
  background-color: white;
  margin-top: -15px;
  z-index: 5;
  cursor: pointer;
}
.suggestion-item {
  padding: 5px;
  cursor: pointer;
  margin-bottom: 0;
}
.suggestion-item:hover {
  background-color: lightgray;
}
</style>